





























import './styles/MemberTasks.scss';
import './styles/MemberTasksAdaptive.scss';
import { Component, Vue } from 'vue-property-decorator';
import { IProjectTasksState, ITask } from '@store/modules/project-tasks/interfaces/Interfaces';
import { namespace } from 'vuex-class';
import { IMemberInfo, IProjectTeamState } from "@store/modules/project-team/interfaces/Interfaces";

const NSUser = namespace('storeUser');
const NSProjectTasks = namespace('storeProjectTasks');
const NSProjectTeam = namespace('storeProjectTeam');

@Component({
    name: 'MemberTasks',
    components: {
        BaseButton: () => import('@/components/buttons/BaseButton.vue'),
    }
})
export default class MemberTasks extends Vue {
    @NSUser.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;
    @NSProjectTasks.State((state: IProjectTasksState) => state.tasks) allTasks!: ITask[];
    @NSProjectTeam.State((state: IProjectTeamState) => state.viewedMember) viewedMember!: IMemberInfo;

    get memberTasks(): ITask[] {
        return this.allTasks.filter((task: ITask) => {
            return task.executor?.uuid === this.viewedMember.uuid
                || task.author?.uuid === this.viewedMember.uuid;
        })
    }

    getStringTaskNumber(taskIndex: number): string {
        if (taskIndex < 10) return `0${taskIndex}`;
        return String(taskIndex);
    }

    emitShowTaskDetail(task) {
        this.$emit('show-task-detail', task);
    }

    emitAddingButtonClick() {
        this.$emit('adding-button-click');
    }
}
